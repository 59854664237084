import React from 'react'
import AppButton from '../../AppButton/AppButton'
import './style.scss'
interface IdeaProps {
  img?:string,
  title?:string,
  desc?:string, 

}

const Idea = ({img, title, desc}:IdeaProps) => {
  return (
    <div className='ideaCard'>
      <img src={img} alt='' className='ideaImage'/>
      <div>{title}</div>
      <div>{desc}</div>
      <AppButton className='tertiary' style={{color:'black', border:'2px solid'}} >Read More </AppButton>
        
    </div>
  )
}

export default Idea