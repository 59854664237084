import React from "react";
import "./style.scss";
import phoneIcon from '../../assets/img/phoneIcon.png'
import mailIcon from '../../assets/img/mailIcon.png'

import facebookIcon from "../../assets/img/facebookIcon.png"
import twitterIcon from "../../assets/img/twitterIcon.png"
import instagramIcon from "../../assets/img/instagramIcon.png"

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_link">
        <h5>Quick Links</h5>
        <div>Home</div>
        <div>About</div>
        <div>Reviews</div>
        <div>Shop</div>
        <div>Packages</div>
        <div>Blogs</div>
      </div>
      <div className="footer_link">
        <h5>Extra Links</h5>
        <div>My Account</div>
        <div>My Order</div>
        <div>My Wishlist</div>
        <div>Ask Questions</div>
        <div>Terms of use</div>
        <div>Privacy Policy</div>
      </div>
      <div className="footer_link">
        <h5>Contact Info</h5>
        <div><img className='phoneIcon' src={phoneIcon} alt='phone'/>&nbsp; &nbsp; 01-4621612</div>
        <div><img className='phoneIcon' src={phoneIcon} alt='phone'/>&nbsp; &nbsp; 01-4621612</div>
        <div><img className='mailIcon' src={mailIcon} alt='mail'/>&nbsp; mountaineer@gmail.com</div>
        
      </div>
      <div className="footer_link">
        <h5>Follow US</h5>
        <div><img className='socialMediaIcon' src={facebookIcon} alt='phone'/>&nbsp;Facebook</div>
        <div><img className='socialMediaIcon' src={twitterIcon} alt='phone'/>&nbsp;Twitter</div>
        <div><img className='socialMediaIcon' src={instagramIcon} alt='phone'/>&nbsp;Instagram</div>
        
      </div>
    </div>
  );
};

export default Footer;
