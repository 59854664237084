import React from 'react'
import AppButton from '../../AppButton/AppButton'
import './style.scss'

interface PackagesProps {
    img?:string,
    title?:string,
    desc?:string,
    cost?:string
}

const Packages = ({img, title,desc,cost}:PackagesProps) => {
  return (
    <div className='packagesCard'>
        <img src={img} alt='test' className='packagesImage'/>
        <div>{title}</div>
        <div style={{fontSize:'14px'}}>{desc}</div>
        <div style={{color:'green'}}>{cost}</div>
        <AppButton className='tertiary' style={{color:'black', border:'2px solid'}} >Explore Now </AppButton>
    </div>
  )
}

export default Packages