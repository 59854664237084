import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import About from '../pages/About';
import Blog from '../pages/Blog';
import Destination from '../pages/Destination';
import Home from '../pages/Home/Home';

const RootRouter = () => {
  return (
    <div>
     <BrowserRouter>
     <Routes>
      <Route path="/" element={<Home/>}/>
       <Route path="/blog" element={<Blog/>}/>
       <Route path="/destination" element={<Destination/>}/>
       <Route path="/about" element={<About/>}/>
       <Route path="*" element={<Home/>}/>
     </Routes>
    </BrowserRouter>

    </div>
  )
}

export default RootRouter