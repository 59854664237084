import React from "react";
import AppButton from "../../AppButton/AppButton";
import "./style.scss";
import calenderIcon from "../../../assets/img/calenderIcon.png";
import adminIcon from "../../../assets/img/adminIcon.png";

interface PostsProps {
  img?: string;
  title?: string;
  desc?: string;
  date?: string;
  role?: string;
}

const Posts = ({ img, title, desc, date, role }: PostsProps) => {
  return (
    <div className="postsCard">
      <img src={img} alt="img" className="postsImage" />
      <div>
        <div
          style={{
            backgroundColor: "black",
            width: "60%",
            margin: "auto",
            marginTop: "-4vh",
            color: "white",
            fontSize:12,
            display:"flex",
            justifyContent:'space-around'
            
          }}
        >
          <img src={calenderIcon} alt="f" />
          {date}
          <img src={adminIcon} alt="admin" />
          {role}
        </div>
      </div>
      <div>{title}</div>
      <div>{desc}</div>
      <AppButton
        className="tertiary"
        style={{ color: "black", border: "2px solid" }}
      >
        Read More{" "}
      </AppButton>
    </div>
  );
};

export default Posts;

//https://images.unsplash.com/photo-1551632811-561732d1e306?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80
