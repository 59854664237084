import React from "react";
import AppButton from "../../components/AppButton/AppButton";
import Offers from "../../components/Cards/Offers/Offers";
import Packages from "../../components/Cards/Packages/Packages";
import Posts from "../../components/Cards/DailyPosts/DailyPosts";
import "./home.scss";
import sponsors1 from "../../assets/img/sponsors1.png"
import sponsors2 from "../../assets/img/sponsors2.png"
import sponsors3 from "../../assets/img/sponsors3.png"
import sponsors4 from "../../assets/img/sponsors4.png"
import Footer from "../../components/Footer/Footer";
import Idea from "../../components/Cards/Idea/Idea";


const Home = () => {
  return (
    <div className="home">
      <h1 >Popular Packages</h1>

      {/* <Offers 
     img='https://www.testingtime.com/app/uploads/2017/07/Grundregeln_fuer_User_Testing-750x500.jpg'
     title="Complete Guide"
     desc="Mount  Everest  looms large, known around the world by hikers"/> */}
      <div className="packages">
        {/* <div className="packages_one"> */}
        <Packages
          img="https://images.unsplash.com/photo-1545652985-5edd365b12eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          title="Annapurna Base Camp Trek"
          desc="Bungee Jumping is one of the most action-packed activities that get your adrenaline pumping."
          cost="$250.0 - $450.0"
        />
        <Packages
          img="https://images.unsplash.com/photo-1545652985-5edd365b12eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          title="Annapurna Base Camp Trek"
          desc="Bungee Jumping is one of the most action-packed activities that get your adrenaline pumping."
          cost="$250.0 - $450.0"
        />
        <Packages
          img="https://images.unsplash.com/photo-1545652985-5edd365b12eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          title="Annapurna Base Camp Trek"
          desc="Bungee Jumping is one of the most action-packed activities that get your adrenaline pumping."
          cost="$250.0 - $450.0"
        />
        <Packages
          img="https://images.unsplash.com/photo-1545652985-5edd365b12eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          title="Annapurna Base Camp Trek"
          desc="Bungee Jumping is one of the most action-packed activities that get your adrenaline pumping."
          cost="$250.0 - $450.0"
        />
        <Packages
          img="https://images.unsplash.com/photo-1545652985-5edd365b12eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          title="Annapurna Base Camp Trek"
          desc="Bungee Jumping is one of the most action-packed activities that get your adrenaline pumping."
          cost="$250.0 - $450.0"
        />
        <Packages
          img="https://images.unsplash.com/photo-1545652985-5edd365b12eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          title="Annapurna Base Camp Trek"
          desc="Bungee Jumping is one of the most action-packed activities that get your adrenaline pumping."
          cost="$250.0 - $450.0"
        />

        {/* </div> */}
      </div>
      <AppButton className="primary" style={{margin:'auto'}}>View More</AppButton>
      <section>
        <h1 >What We Offer</h1>
        <div className="offers">
          <Offers
            img="https://www.testingtime.com/app/uploads/2017/07/Grundregeln_fuer_User_Testing-750x500.jpg"
            title="Complete Guide"
            desc="Mount  Everest  looms large, known around the world by hikers"
          />
          <Offers
            img="https://www.testingtime.com/app/uploads/2017/07/Grundregeln_fuer_User_Testing-750x500.jpg"
            title="Complete Guide"
            desc="Mount  Everest  looms large, known around the world by hikers"
          />
          <Offers
            img="https://www.testingtime.com/app/uploads/2017/07/Grundregeln_fuer_User_Testing-750x500.jpg"
            title="Complete Guide"
            desc="Mount  Everest  looms large, known around the world by hikers"
          />
          <Offers
            img="https://www.testingtime.com/app/uploads/2017/07/Grundregeln_fuer_User_Testing-750x500.jpg"
            title="Complete Guide"
            desc="Mount  Everest  looms large, known around the world by hikers"
          />
          <Offers
            img="https://www.testingtime.com/app/uploads/2017/07/Grundregeln_fuer_User_Testing-750x500.jpg"
            title="Complete Guide"
            desc="Mount  Everest  looms large, known around the world by hikers"
          />
          <Offers
            img="https://www.testingtime.com/app/uploads/2017/07/Grundregeln_fuer_User_Testing-750x500.jpg"
            title="Complete Guide"
            desc="Mount  Everest  looms large, known around the world by hikers"
          />
        </div>
      </section>
      <h1>Our Daily Posts</h1>
      <div className="posts">
        <Posts
          img="https://images.unsplash.com/photo-1551632811-561732d1e306?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          title="Test"
          desc="Test"
          date='2020-01-01'
          role='Admin'
        />
        <Posts
          img="https://images.unsplash.com/photo-1551632811-561732d1e306?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          title="Test"
          desc="Test"
          date='2020-01-01'
          role='Admin'
        />
        <Posts
          img="https://images.unsplash.com/photo-1551632811-561732d1e306?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          title="Test"
          desc="Test"
          date='2020-01-01'
          role='Admin'
        />
      </div>
      <h1>Adventure Idea</h1>
      <div className="idea">
        
        <Idea
        img="https://images.unsplash.com/photo-1551632811-561732d1e306?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
        title="test"
        desc="test"
        />
        <Idea
        img="https://images.unsplash.com/photo-1551632811-561732d1e306?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
        title="test"
        desc="test"
        />
        <Idea
        img="https://images.unsplash.com/photo-1551632811-561732d1e306?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
        title="test"
        desc="test"
        />

      </div>
      <div className="subscription">
        <div>Subscribe Now</div>
        <div>   mountaineering, also called mountain climbing, the sport of attaining, or attempting to 
        attain, high points in mountainous regions, mainly for the pleasure of the climb. </div>
        <div>
        {/* <input type='text' className="primary" style={{width:'60%', height:'8.5vh'}}/>
          <AppButton className="tertiary"> Subscribe</AppButton> */}
          <input type="text"/>
         <button className="subscribe-btn">Subscribe </button>
        </div>
       
        
      </div>
      <div className="sponsors">
        <img src={sponsors1} alt='sponsor'/>
        <img src={sponsors2} alt='sponsor'/>
        <img src={sponsors3} alt='sponsor'/>
        <img src={sponsors4} alt='sponsor'/>
      </div>
      
      <Footer/>
    </div>
  );
};

export default Home;
