import React from 'react';
import { Spinner } from 'react-bootstrap';
import './style.scss'

interface IAppButtonProps {
  className: string;
  children?: any;
  style?: any;
  onClick?: any;
  fullWidth?: boolean;
  size?: string;
  disabled?: boolean;
  type?: any;
  isLoading?: boolean;
  title?: string;
  id?: string;
}

const AppButton: React.FC<IAppButtonProps> = ({ className, ...props }: IAppButtonProps) => {
  return (
    <button
      title={props.title}
      className={`appButton${props.size ? `-${props.size}` : ``} ${className}`}
      {...props}
    >
      {props.children}
      {props.isLoading && (
        <Spinner
          animation="border"
          role="status"
          variant="light"
          size="sm"
          style={{ marginLeft: '8px' }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </button>
  );
};

export default AppButton;
