import React from 'react'
import Slider from '../components/Slider/HeroSlider/HeroSlider'

const Blog = () => {
  return (
    <div>
      <Slider/>
    </div>
  )
}

export default Blog