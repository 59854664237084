import React from 'react'
import AppButton from '../../AppButton/AppButton'
import './style.scss'

interface OffersProps {
    img?:string, 
    title?:string, 
    desc?:string,
}

const Offers = ({img, title, desc}:OffersProps) => {
  return (
    <div className="offersCard">
        <img src={img} alt='desc' className="offersImage"/>
        <div style={{color:'green', fontSize:'20px'}}>{title}</div>
        <div>{desc}</div>
        <AppButton className='tertiary' style={{color:'black', border:'2px solid'}} >Read More </AppButton>


    </div>
  )
}

export default Offers